$(function() {

	// Get the form.
	var formProject = $('#projectForm');
  var formSupport = $('#supportForm');
  var formSubscribe = $('#subscribeForm');


  // Get the messages div.
	var formProjectMessages = $('#projectModal #form-messages-project');
	var formSupportMessages = $('#supportModal #form-messages');
	var formSubscribeMessages = $('#subscribe #form-messages-subscribe');

	// Set up an event listener for the contact form.
	$(formProject).submit(function(e) {
		// Stop the browser from submitting the form.
		e.preventDefault();

		// Serialize the form data.
		var formData = $(formProject).serialize();

    if (grecaptcha.getResponse($('#recaptcha2').attr('data-widget-id'))) {
      // Submit the form using AJAX.
      $.ajax({
        type: 'POST',
        url: $(formProject).attr('action'),
        data: formData
      })
        .done(function(response) {
          // Make sure that the formMessages div has the 'success' class.
          $(formProjectMessages).removeClass('error');
          $(formProjectMessages).addClass('success');

          // Set the message text.
          $(formProjectMessages).text(response);
          $(formProject).hide();
          // Clear the form.
          $('#name').val('');
          $('#email').val('');
          $('#message').val('');
        })
        .fail(function(data) {
          // Make sure that the formMessages div has the 'error' class.
          $(formProjectMessages).removeClass('success');
          $(formProjectMessages).addClass('error');

          // Set the message text.
          if (data.responseText !== '') {
            $(formProjectMessages).text(data.responseText);
          } else {
            $(formProjectMessages).text('Oops! An error occurred and your message could not be sent.');
          }
        });
    }

    else {
      $(formProjectMessages).removeClass('success');
      $(formProjectMessages).addClass('error');
      $(formProjectMessages).text('Please confirm captcha to proceed');
    }
	});

  $(formSupport).submit(function(e) {
    // Stop the browser from submitting the form.
    e.preventDefault();

    // Serialize the form data.
    var formData = $(formSupport).serialize();
    if (grecaptcha.getResponse($('#recaptcha1').attr('data-widget-id'))) {
      // Submit the form using AJAX.
      $.ajax({
        type: 'POST',
        url: $(formSupport).attr('action'),
        data: formData
      })
        .done(function(response) {
          // Make sure that the formMessages div has the 'success' class.
          $(formSupportMessages).removeClass('error');
          $(formSupportMessages).addClass('success');

          // Set the message text.
          $(formSupportMessages).text(response);
          $(formSupport).hide();
          // Clear the form.
          $('#name').val('');
          $('#email').val('');
          $('#message').val('');
        })
        .fail(function(data) {
          // Make sure that the formMessages div has the 'error' class.
          $(formSupportMessages).removeClass('success');
          $(formSupportMessages).addClass('error');

          // Set the message text.
          if (data.responseText !== '') {
            $(formSupportMessages).text(data.responseText);
          } else {
            $(formSupportMessages).text('Oops! The following fields are required: Name, Email, Phone.');
          }
        });
    }

    else {
      $(formSupportMessages).removeClass('success');
      $(formSupportMessages).addClass('error');
      $(formSupportMessages).text('Please confirm captcha to proceed');
    }
  });

  $(formSubscribe).submit(function(e) {
    // Stop the browser from submitting the form.
    e.preventDefault();

    // Serialize the form data.
    var formData = $(formSubscribe).serialize();
    if (grecaptcha.getResponse($('#recaptcha3').attr('data-widget-id'))) {
      // Submit the form using AJAX.
      $.ajax({
        type: 'POST',
        url: $(formSubscribe).attr('action'),
        data: formData
      })
        .done(function(response) {
          // Make sure that the formMessages div has the 'success' class.
          $(formSubscribeMessages).removeClass('error');
          $(formSubscribeMessages).addClass('success');

          // Set the message text.
          $(formSubscribeMessages).text(response);
          $(formSubscribe).hide();
          // Clear the form.
          $('#email').val('');
        })
        .fail(function(data) {
          // Make sure that the formMessages div has the 'error' class.
          $(formSubscribeMessages).removeClass('success');
          $(formSubscribeMessages).addClass('error');

          // Set the message text.
          if (data.responseText !== '') {
            $(formSubscribeMessages).text(data.responseText);
          } else {
            $(formSubscribeMessages).text('Oops! The following fields are required:Email');
          }
        });
    }

    else {
      $(formSubscribeMessages).removeClass('success');
      $(formSubscribeMessages).addClass('error');
      $(formSubscribeMessages).text('Please confirm captcha to proceed');
    }
  });
});
